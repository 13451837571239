import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import icon from './icon.ico'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  sampleFastaSequence,
  sampleFastaName,
  sampleFastaUrl,
} from './sampleFasta'

const noSampleLink = 'http://covbrowser.org/jbrowse/?data=data%2FSARS-CoV-2'

function App() {
  const [captcha, setCaptcha] = useState()
  const [key, setKey] = useState(new Date())
  const [fastaText, setFastaText] = useState('')
  const [nameText, setNameText] = useState('')
  const [resultsUrl, setResultsUrl] = useState('')
  const [resultsMessage, setResultsMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [hasFileInfo, setHasFileInfo] = useState(false)
  const [errors, setErrors] = useState('')
  const [warnings, setWarnings] = useState([])

  const fileInput = useRef()
  const recaptchaRef = useRef()

  useEffect(() => {
    if (getFileInfo() && !hasFileInfo) {
      setHasFileInfo(true)
    } else if (!getFileInfo && hasFileInfo) {
      setHasFileInfo(false)
    }
  })

  useEffect(() => {
    let baseregx = /^[ACGTUMRWSYKVHDBN]+$/g // includes all IUPAC ambiguity codes
    if (!fastaText) {
      setErrors('')
      setWarnings([])
      return
    }

    // Generates the DNA/RNA sequence without newlines or spaces
    let fullSequence =
      fastaText.charAt(0) === '>'
        ? fastaText.split('\n').slice(1).join('')
        : fastaText.replace(/[\s\n]+/g, '')

    let seqHeaders = fastaText.match(/^>/gm)

    //Check for any errors on sequence
    if (seqHeaders && seqHeaders.length > 1) {
      setErrors(
        'This site is designed for uploading individual genomes only. Detected multiple sequences in the pasted FASTA.'
      )
    } else if (
      fullSequence.length > 0 &&
      !baseregx.test(fullSequence.toUpperCase())
    ) {
      setErrors(
        ' The sequence pasted contains non-valid characters. Please double check your sequence'
      )
    } else if (fullSequence.length > 40000 || fullSequence.length < 100) {
      setErrors(
        'The sequence size is not within range. Please double check your sequence'
      )
    }
    // No errors, check for warnings but submit is allowed
    else {
      setErrors('')
      const warnings = []
      if (fullSequence.length < 20000) {
        warnings.push(
          ' The sequence pasted is less than 20kb, please double check before submitting.'
        )
      }
      if (fastaText.charAt(0) !== '>') {
        warnings.push(
          'The FASTA sequence seems to be missing a header. One will be automatically added for you'
        )
      }
      setWarnings(warnings)
      return
    }
    setWarnings([])
  }, [fastaText])

  /* getters */
  function getFileInfo() {
    return (fileInput.current && fileInput.current.files[0]) || undefined
  }
  function getNameInfo() {
    return nameText
  }
  // Parse fasta file and create sequence object

  function generateName(fastaText = '') {
    let seq = {}
    let name
    let re = /^>(\S+)/
    fastaText.split('\n').forEach((line) => {
      const match = re.exec(line)
      if (match) {
        seq[(name = match[1])] = ''
      } else if (name) {
        seq[name] = seq[name] + line.replace(/[ \t]/g, '')
      }
    })
    const textInfo = fastaText
    const fileInfo = fileInput.current.files[0]

    if (!fastaText && !fileInfo) {
      setNameText('')
      return
    }

    let guessName = ''
    if (textInfo) {
      guessName = Object.keys(seq)[0]
    } else {
      guessName = fileInfo.name.replace(/\.[^/.]+$/, '')
    }

    if (fileInfo || Object.keys(seq).length > 0) {
      setNameText(guessName)
    } else {
      setNameText('NewSequence')
    }
  }

  /* Main function, handles POST request and response*/
  async function handleSubmit() {
    setResultsUrl('')
    setResultsMessage('')
    setSubmitted(true)
    let text = fastaText
    const file = getFileInfo()
    const name = getNameInfo()

    if (
      text &&
      text === sampleFastaSequence &&
      name &&
      name === sampleFastaName
    ) {
      setLoading(true)
      setTimeout(() => {
        setResultsUrl(sampleFastaUrl)
        setResultsMessage('JBrowse Link:')
        setLoading(false)
      }, 1000)
      return
    }

    if (!text && !file) {
      return
    }

    if (text && text.charAt(0) !== '>') {
      text = `>NewSequence\n${text}`
    }

    const endpointURL = 'https://api.covbrowser.org/api/v1/submit'
    const formData = new FormData()
    formData.append('sequence', text || file)
    formData.append(
      'metadata',
      JSON.stringify({
        name,
        recaptchaResponse: recaptchaRef.current.getValue(),
      })
    )
    setLoading(true)
    try {
      const response = await fetch(endpointURL, {
        method: 'POST',
        body: formData,
      })
      if (response.ok) {
        const res = await response.json()
        setResultsUrl(res.resultsUrl)
        setResultsMessage('JBrowse Link:')
      } else {
        const res = await response.json()
        if (res.message === 'Error: No variation detected in sequence') {
          setResultsUrl(noSampleLink)
          setResultsMessage(
            'No variation detected. The uploaded sequence may be identical to the reference sequence. Could not produce sample-specific variant track. If you would still like to view JBrowse with all the other available annotation tracks, you can visit:'
          )
        } else {
          alert(res.message)
        }
      }
    } catch (e) {
      console.error(e)
      alert(e)
    } finally {
      setLoading(false)
    }
    recaptchaRef.current.reset()
  }

  return (
    <>
      <span className="topSpan">
        <h2 className="jbrowseHeader">JBrowse SARS-Cov2 Generator</h2>
        <img alt="logo" src={icon} className="icon" align="right" />
      </span>
      <hr />

      <div id="parentDiv">
        <div id="innerDiv">
          <p>
            <label>Paste or upload FASTA</label>{' '}
            <a
              href="#"
              onClick={() => {
                setFastaText(sampleFastaSequence)
                generateName(sampleFastaSequence)
              }}
            >
              (example)
            </a>
          </p>
          <div id="inputDiv" name="inputDiv">
            <textarea
              id="fastaTextInput"
              className="input"
              rows="5"
              cols="80"
              onInput={(event) => {
                setResultsUrl('')
                setResultsMessage('')

                let text = event.target.value

                setFastaText(text)
                generateName(text)
              }}
              value={fastaText}
              placeholder="Paste FASTA here"
              spellCheck="false"
              disabled={hasFileInfo || submitted}
            ></textarea>
            <input
              key={key}
              type="file"
              id="fastaFileInput"
              className="input"
              accept=".fasta, .fa"
              ref={fileInput}
              onChange={() => {
                generateName()
              }}
              disabled={Boolean(fastaText) || submitted}
            ></input>

            <div className="error"> {errors} </div>
            {warnings.map((warning) => (
              <div className="warning" key={warning}>
                {warning}
              </div>
            ))}
            <div>
              <label>Sample Name</label>
            </div>
            <textarea
              id="nameTextInput"
              className="input"
              rows="1"
              cols="50"
              onInput={(event) => {
                setNameText(event.target.value)
              }}
              value={nameText}
              placeholder="Name"
              spellCheck="false"
              disabled={submitted || !nameText}
            ></textarea>
          </div>
          {submitted ? (
            <button
              type="buttom"
              onClick={() => {
                setSubmitted(false)
                setResultsUrl('')
                setResultsMessage('')
                setCaptcha(null)
                setKey(new Date())
                setFastaText('')
                setNameText('')
                recaptchaRef.current.reset()
              }}
            >
              Submit another
            </button>
          ) : (
            <>
              <button
                type="button"
                id="submit"
                className="submitButton"
                onClick={handleSubmit}
                disabled={!captcha || !nameText || errors}
              >
                Submit
              </button>
              <button
                type="button"
                id="clear"
                className="clearButton"
                onClick={() => {
                  setFastaText('')
                  setNameText('')
                  setKey(new Date())
                  setHasFileInfo(false)
                }}
              >
                Clear All
              </button>
            </>
          )}
        </div>
        <div
          className="g-recaptcha"
          style={{ display: submitted ? 'none' : undefined }}
        >
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LeFdeMUAAAAAL0_7Uagq6T-K3LCW5qft-ho0g0i"
            onChange={(value) => {
              setCaptcha(value)
            }}
          />
        </div>

        <label style={{ display: loading ? 'block' : 'none' }}>
          Loading...
        </label>
        <div id="resultsDiv">
          {resultsUrl ? (
            <>
              <p>{resultsMessage}</p>
              <a rel="noreferrer noopener" target="_blank" href={resultsUrl}>
                {resultsUrl}
              </a>
            </>
          ) : null}
        </div>
      </div>
      <Readme />
    </>
  )
}
function Readme() {
  return (
    <div className="readme">
      <p>
        <i>
          This site is a free pastebin for SARS-CoV-2 genomes. Uploaded
          sequences are mapped to the{' '}
          <a href="https://www.ncbi.nlm.nih.gov/nuccore/NC_045512">RefSeq</a>{' '}
          (using{' '}
          <a href="https://www.ncbi.nlm.nih.gov/pubmed/29750242">minimap2</a>)
          and variants called (using{' '}
          <a href="https://samtools.github.io/bcftools/howtos/variant-calling.html">
            bcftools
          </a>
          ). The results are displayed in{' '}
          <a href="https://jbrowse.org">JBrowse</a> at a permanent URL,
          alongside a common set of SARS-CoV-2 annotation tracks (
          <a href="https://github.com/GMOD/sars-cov-2-jbrowse/tree/master/jbrowse/data/SARS-CoV-2">
            source
          </a>
          ). An example for GenBank entry{' '}
          <a href="https://www.ncbi.nlm.nih.gov/nuccore/MT188340">MT188340</a>{' '}
          can be found{' '}
          <a href="http://covbrowser.org/jbrowse/?data=data%2Fa26bc665-c005-477e-8ddb-1e80f21e2d55&loc=NC_045512.2%3A1..29903&tracks=DNA%2CCDS%2CGenes%2C_MT188340&highlight=">
            here
          </a>
          .{' '}
          <b>
            Uploaded sequences can be shared simply by sharing the link. Anyone
            with the link can view the results.
          </b>
        </i>
      </p>
    </div>
  )
}
export default App
